<script setup lang="ts">
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import { customAlphabet } from 'nanoid'
import { computed, ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerCardFormNotification from '/~/components/drawer/components/payment-methods/forms/drawer-card-form-notification.vue'
import DrawerMethodTile from '/~/components/drawer/components/tiles/drawer-method-tile.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useLocalization } from '/~/composables/localization'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

const emit = defineEmits<(event: 'submit') => void>()

const router = useRouter()
const route = useRoute()
const { isDarkThemeForEwallet } = useCms()
const {
  creating,
  formLoading,
  formReady,
  creditCardFormData,
  createCreditCardForm,
  isMethodAvailable,
} = usePaymentMethods()

const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz', 16)
const containerId = nanoid()

const tocAccepted = ref(false)
const form = ref(null)
const container = ref(null)

const { translate } = useLocalization()

const isOneCardEnabled = computed(() => {
  return isMethodAvailable(PaymentMethodType.creditCard, 'oneCardLimit')
})

const isDisabled = computed(
  () =>
    creating.value ||
    formLoading.value ||
    !tocAccepted.value ||
    !cardPresentation.value.isValid ||
    !cardPresentation.value.isReadyToSubmit
)

watch(formReady, (value) => {
  if (value && !tocAccepted.value) {
    form.value?.disableSubmit()
  }
})

watch(isDarkThemeForEwallet, (value) => {
  form.value?.changeTheme(value ? 'dark' : 'light')
})

onMounted(() => {
  createForm()
})

onBeforeUnmount(() => {
  form.value?.dispose()
  emitter.off('payment-methods:created', onComplete)
})

async function createForm(options) {
  await form.value?.dispose?.()
  container.value.setAttribute('id', containerId)
  form.value = await createCreditCardForm({
    containerId: `#${containerId}`,
    onFailed,
    ...options,
  })
}

function onCancelForm() {
  if (route.meta?.back) {
    router.push(route.meta.back)
  } else {
    router.back()
  }
}

async function onSubmitForm() {
  if (formLoading.value || isDisabled.value) {
    return
  }

  try {
    emitter.on('payment-methods:created', onComplete)
    form.value.submit()
  } catch (error) {
    emitter.off('payment-methods:created', onComplete)
    console.error(error)
  }
}

const cardPresentation = computed(() => {
  const { data, isValid, isReadyToSubmit } = creditCardFormData.value
  const { cardholder, number, expiryMonth, expiryYear } = data ?? {}

  return {
    type: PaymentMethodType.creditCard,
    brand: getCardTypeByNumber(number)?.type,
    number: number || '',
    name: cardholder,
    expiry: {
      month:
        expiryMonth < 10 ? `0${expiryMonth}` : expiryMonth?.toString() || '',
      year: expiryYear?.toString() || '',
    },
    isValid,
    isReadyToSubmit,
  }
})

function onComplete(newCard) {
  emitter.off('payment-methods:created', onComplete)
  createForm()
  emit('submit', { ...newCard, type: PaymentMethodType.creditCard })
}

async function onFailed() {
  const data = creditCardFormData.value?.data

  await createForm({
    onMounted: () => {
      form.value.prefill(data)
      form.value.enableSubmit()
    },
  })
}

function toggleTocCheckbox(isActivated) {
  if (isActivated) {
    form.value.enableSubmit()
  } else {
    form.value.disableSubmit()
  }
}

function showTermsAndConditions() {
  if (route.meta?.bottomSheetTerms) {
    bottomSheet.show('terms-conditions', { to: 'menu-modal' })
  } else {
    router.push({ hash: '#profile-toc' })
  }
}
</script>

<template>
  <div
    class="relative -mb-5 flex grow flex-col items-center justify-between"
    :style="{
      '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
    }"
  >
    <div class="w-full">
      <base-loader v-if="formLoading" class="py-5" fullwidth />
      <template v-else>
        <drawer-method-tile :method="cardPresentation" />
        <drawer-card-form-notification class="-mb-5 mt-5" />
      </template>
    </div>
    <div class="mt-10 w-full flex-auto" @keyup.enter="onSubmitForm">
      <div
        :id="containerId"
        ref="container"
        :class="{
          'pointer-events-none opacity-50': creating,
          absolute: formLoading, // prevent scroll while loading
        }"
      />
      <div v-if="isOneCardEnabled" class="mt-4 text-neutral-400">
        * Please note that your card may only be linked to a single profile.
      </div>
    </div>

    <div class="sticky bottom-0 w-full bg-white">
      <template v-if="!formLoading">
        <div class="mt-4">
          <base-checkbox
            v-model="tocAccepted"
            :disabled="creating"
            @change="toggleTocCheckbox"
          >
            I agree to the
            <base-action
              class="text-primary underline"
              type="link"
              :click-only="true"
              @click="showTermsAndConditions"
            >
              Terms {{ translate('common.and') }} Conditions
            </base-action>
          </base-checkbox>
        </div>
      </template>
      <div class="my-4 grid grid-cols-2 gap-x-2">
        <base-button class="w-full" look="outlined-color" @click="onCancelForm">
          Cancel
        </base-button>
        <base-button
          class="w-full"
          type="primary"
          :disabled="isDisabled"
          @click="onSubmitForm"
        >
          {{ creating ? 'Saving...' : 'Save' }}
        </base-button>
      </div>
    </div>
  </div>
</template>
